import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import FieldsSeparator from '@/models/FieldsSeparator';
import NoDataStr from '@/models/NoDataStr';
import * as PropertiesTray from '@/models/PropertiesTray';
import StatsRequest from '@/models/StatsRequest';

import {
  actions as ipIntelActions,
  selectors as ipIntelSelectors,
} from '@/redux/api/ipintel';
import {
  actions as searchActions,
  selectors as searchSelectors,
} from '@/redux/api/search';

import Button from '+components/Button';
import BlockTable, {
  Columns as BlockTableColumns,
} from '+components/ContextTables/BlockTable';
import EventTable, {
  Columns as AlertTableColumns,
} from '+components/ContextTables/EventTable';
import Flag from '+components/Flag';
import { Field, FinalForm } from '+components/form/FinalForm';
import FormOrigin from '+components/form/Form';
import renderTextField from '+components/form/TextField';
import {
  validateIp,
  validateIpV6,
  validateRequired,
} from '+components/form/Validators';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import Table from '+components/Table';
import { UniversalCell } from '+components/Table/Cells';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import {
  BaseColumnFactory,
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
  NumberColumnFactory,
} from '+components/Table/Columns';
import Tag from '+components/Tag';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';
import useStatsRequest from '+hooks/useStatsRequest';
import useUIProperty from '+hooks/useUIProperty';
import { UnderlineMixin } from '+theme/mixins/underlineMixin';
import { timeBounds } from '+utils';
import { makeId } from '+utils/general';
import nqlLang from '+utils/nqlLang';

import Card from './components/Card';
import CardBody from './components/CardBody';
import CardName from './components/CardName';
import Container from './components/Container';
import Group from './components/Group';
import GroupBody from './components/GroupBody';
import IpExplorer from './components/IpExplorer';
import Item from './components/Item';
import ItemBody from './components/ItemBody';
import ItemName from './components/ItemName';
import Map from './components/Map';
import Row from './components/Row';

const Form = styled(FormOrigin)`
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .form__form-group {
    width: 25em;
    margin-bottom: unset;
  }
`;

const FormBody = ({ handleSubmit, grabFormSubmit, isFetching }) => {
  usePageTabsAndFormSync();

  return (
    <Form onSubmit={grabFormSubmit(handleSubmit)}>
      <Field
        name="ip"
        type="text"
        placeholder="IP Address"
        component={renderTextField}
        validate={[validateRequired, validateIp, validateIpV6]}
        required
      />
      <Button
        style={{ marginLeft: '10px' }}
        type="submit"
        disabled={isFetching}
        data-tracking="ip-intel-search-button"
      >
        Search
      </Button>
    </Form>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  grabFormSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const GeoContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  ${UnderlineMixin}
`;

const flowRequestId = makeId();
const alertsDstipRequestId = makeId();
const alertsSrcipRequestId = makeId();
const blocksDstipRequestId = makeId();
const blocksSrcipRequestId = makeId();

const srcportsRequestName = 'top-10-srcports';
const dstportsRequestName = 'top-10-dstports';
const protocolsRequestName = 'top-10-protocols';
const srcipsRequestName = 'top-10-srcips';
const dstipsRequestName = 'top-10-dstips';

const srcportsRequestFields = (labelContext) =>
  [
    'srcport',
    labelContext.show && `label.port.${labelContext.port}.src`,
  ].filter(Boolean);
const dstportsRequestFields = (labelContext) =>
  [
    'dstport',
    labelContext.show && `label.port.${labelContext.port}.dst`,
  ].filter(Boolean);
const protocolsRequestFields = () => ['protocol'];
const srcipsRequestFields = (labelContext) =>
  ['srcip', labelContext.show && `label.ip.${labelContext.ip}.src`].filter(
    Boolean,
  );
const dstipsRequestFields = (labelContext) =>
  ['dstip', labelContext.show && `label.ip.${labelContext.ip}.dst`].filter(
    Boolean,
  );

const srcportsTableName = 'SrcportsIpIntelligence';
const dstportsTableName = 'DstportsIpIntelligence';
const protocolsTableName = 'ProtocolsIpIntelligence';
const srcipsTableName = 'SrcipsIpIntelligence';
const dstipsTableName = 'DstipsIpIntelligence';
const alertsSrcipTableName = 'AlertsSrcipIpIntelligence';
const alertsDstipTableName = 'AlertsDstipIpIntelligence';
const blocksSrcipTableName = 'BlocksSrcipIpIntelligence';
const blocksDstipTableName = 'BlocksDstipIpIntelligence';

const portColumns = (direction, field, labelContext) => [
  LabelOrPortColumnFactory({
    Header: `${direction.toLocaleUpperCase()} Port`,
    dataFieldName: field[0],
    labelFieldName: field[1],
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    accessor: 'count',
    Header: 'flows',
    Cell: ({ value }) => (+value).toLocaleString(),
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
  }),
  NumberColumnFactory({
    accessor: 'percent',
    Cell: ({ value }) =>
      `${(+value * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })}%`,
  }),
];

const protocolColumns = (field) => [
  BaseColumnFactory({
    accessor: field[0],
    Header: 'Protocol',
    Cell: UniversalCell(field[0]),
  }),
  NumberColumnFactory({
    accessor: 'count',
    Header: 'flows',
    Cell: ({ value }) => (+value).toLocaleString(),
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
  }),
  NumberColumnFactory({
    accessor: 'percent',
    Cell: ({ value }) =>
      `${(+value * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })}%`,
  }),
];

const ipColumns = (direction, field, labelContext) => [
  LabelOrIpColumnFactory({
    Header: `${direction.toLocaleUpperCase()} IP`,
    dataFieldName: field[0],
    labelFieldName: field[1],
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    accessor: 'count',
    Header: 'flows',
    Cell: ({ value }) => (+value).toLocaleString(),
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
  }),
  NumberColumnFactory({
    accessor: 'percent',
    Cell: ({ value }) =>
      `${(+value * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })}%`,
  }),
];

const IpIntelligence = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);

  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const defaultIp = useMemo(() => search.get('ip') || '', [search]);

  const [currentIp, setCurrentIp] = useState('');

  const [filters] = useGlobalFilters();
  const { start, end } = timeBounds(filters);

  const optionsIps = useMemo(
    () => ({
      fetchExact: !filters.labelContext.show ? null : currentIp,
    }),
    [filters.labelContext.show, currentIp],
  );
  const { ipLabelsHash } = useIpLabels(optionsIps);

  const isIpIntelFetching = useSelector(ipIntelSelectors.isFetching);
  const intelData = useSelector(ipIntelSelectors.getIpIntel(currentIp));
  const { ip, geo, as, firstseen, lastseen, bogon, iprep, rdns, pdns } =
    intelData;

  const labels = useMemo(
    () =>
      !filters.labelContext.show
        ? []
        : ipLabelsHash[currentIp]?.[filters.labelContext.ip],
    [currentIp, ipLabelsHash, filters.labelContext],
  );

  const flowRequest = useMemo(
    () => ({
      seriesId: flowRequestId,
      params: {
        format: 'keymap',
        start,
        end,
        series: [
          {
            name: srcportsRequestName,
            field: srcportsRequestFields(filters.labelContext),
            fields_separator: FieldsSeparator,
            size: 10,
            metric: 'flows',
            ...StatsRequest.makeSearch({
              search: [nqlLang.equal('srcip', ip)],
            }),
          },
          {
            name: dstportsRequestName,
            field: dstportsRequestFields(filters.labelContext),
            fields_separator: FieldsSeparator,
            size: 10,
            metric: 'flows',
            ...StatsRequest.makeSearch({
              search: [nqlLang.equal('dstip', ip)],
            }),
          },
          {
            name: protocolsRequestName,
            field: protocolsRequestFields(),
            size: 10,
            metric: 'flows',
            ...StatsRequest.makeSearch({
              search: [
                nqlLang.or(
                  nqlLang.equal('srcip', ip),
                  nqlLang.equal('dstip', ip),
                ),
              ],
            }),
          },
          {
            name: srcipsRequestName,
            field: srcipsRequestFields(filters.labelContext),
            fields_separator: FieldsSeparator,
            size: 10,
            metric: 'flows',
            ...StatsRequest.makeSearch({
              search: [nqlLang.equal('dstip', ip)],
            }),
          },
          {
            name: dstipsRequestName,
            field: dstipsRequestFields(filters.labelContext),
            fields_separator: FieldsSeparator,
            size: 10,
            metric: 'flows',
            ...StatsRequest.makeSearch({
              search: [nqlLang.equal('srcip', ip)],
            }),
          },
        ],
      },
    }),
    [ip, flowRequestId, start, end, filters.labelContext],
  );

  const { series: flowSeries, isFetching: isFlowFetching } = useStatsRequest({
    context: ContextTypes.flow,
    requestType: StatsRequest.Types.agg,
    request: flowRequest,
    stopRequest: !ip,
    stopPollingHeartbeat: true,
  });

  const srcportsData = useMemo(() => {
    const data =
      flowSeries?.find((item) => item.name === srcportsRequestName)?.buckets ||
      [];
    const sum = data.reduce((acc, el) => acc + el.count, 0);
    return data.map((el) => ({
      ...el,
      percent: el.count / sum,
    }));
  }, [flowSeries]);

  const dstportsData = useMemo(() => {
    const data =
      flowSeries?.find((item) => item.name === dstportsRequestName)?.buckets ||
      [];
    const sum = data.reduce((acc, el) => acc + el.count, 0);
    return data.map((el) => ({
      ...el,
      percent: el.count / sum,
    }));
  }, [flowSeries]);

  const protocolsData = useMemo(() => {
    const data =
      flowSeries?.find((item) => item.name === protocolsRequestName)?.buckets ||
      [];
    const sum = data.reduce((acc, el) => acc + el.count, 0);
    return data.map((el) => ({
      ...el,
      percent: el.count / sum,
    }));
  }, [flowSeries]);

  const srcipsData = useMemo(() => {
    const data =
      flowSeries?.find((item) => item.name === srcipsRequestName)?.buckets ||
      [];
    const sum = data.reduce((acc, el) => acc + el.count, 0);
    return data.map((el) => ({
      ...el,
      percent: el.count / sum,
    }));
  }, [flowSeries]);

  const dstipsData = useMemo(() => {
    const data =
      flowSeries?.find((item) => item.name === dstipsRequestName)?.buckets ||
      [];
    const sum = data.reduce((acc, el) => acc + el.count, 0);
    return data.map((el) => ({
      ...el,
      percent: el.count / sum,
    }));
  }, [flowSeries]);

  const isSearchFetching = useSelector(searchSelectors.isFetching);
  const alertsSrcipData = useSelector(
    searchSelectors.getSearchResult(alertsSrcipRequestId),
  );
  const alertsDstipData = useSelector(
    searchSelectors.getSearchResult(alertsDstipRequestId),
  );
  const blocksSrcipData = useSelector(
    searchSelectors.getSearchResult(blocksSrcipRequestId),
  );
  const blocksDstipData = useSelector(
    searchSelectors.getSearchResult(blocksDstipRequestId),
  );

  const isFetching = isIpIntelFetching || isFlowFetching || isSearchFetching;

  useLoadingIndicator(isFetching);

  const performSearch = useCallback(
    (values) => {
      const normalizedIP = (values.ip || '').split(' (')[0];
      setCurrentIp(normalizedIP);

      if (search.get('ip') !== normalizedIP) {
        search.set('ip', normalizedIP);
        navigate({ search: search.toString() });
      }

      dispatch(ipIntelActions.fetchIpIntel(normalizedIP));
    },
    [navigate, search],
  );

  const initialValues = useMemo(() => ({ ip: defaultIp }), [defaultIp]);

  const onGeoItemClick = useCallback(
    ({ field, value, title }) =>
      () => {
        setPropertiesTray({
          data: [
            {
              dataType: PropertiesTray.DataTypes.field,
              title,
              field,
              value,
            },
          ],
          isOpen: true,
        });
      },
    [],
  );

  const formSubmit = useRef();
  const grabFormSubmit = useCallback((handleSubmit) => {
    formSubmit.current = handleSubmit;

    return handleSubmit;
  }, []);

  useEffect(() => {
    if (initialValues?.ip === currentIp) {
      return undefined;
    }

    const timer = setTimeout(() => {
      formSubmit.current?.();
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [initialValues.ip, currentIp]);

  // Alerts
  useEffect(() => {
    if (!ip) {
      return undefined;
    }

    const alertsRequestParams = {
      id: alertsSrcipRequestId,
      context: ContextTypes.alerts,
      start,
      end,
      size: 10,
      ...StatsRequest.makeSearch({
        search: [
          nqlLang.and(
            nqlLang.equal('ipinfo.ip', ip),
            nqlLang.equal('ipinfo.srcip', true),
          ),
        ],
      }),
      sort: {
        field: 'timestamp',
        order: 'desc',
      },
    };

    dispatch(searchActions.searchClear({ id: alertsSrcipRequestId }));
    dispatch(searchActions.search(alertsRequestParams));

    return () => {
      dispatch(searchActions.cancel(alertsSrcipRequestId));
    };
  }, [ip, alertsSrcipRequestId, start, end]);

  useEffect(
    () => () => {
      dispatch(searchActions.searchClear({ id: alertsSrcipRequestId }));
    },
    [alertsSrcipRequestId],
  );

  useEffect(() => {
    if (!ip) {
      return undefined;
    }

    const alertsRequestParams = {
      id: alertsDstipRequestId,
      context: ContextTypes.alerts,
      start,
      end,
      size: 10,
      ...StatsRequest.makeSearch({
        search: [
          nqlLang.and(
            nqlLang.equal('ipinfo.ip', ip),
            nqlLang.equal('ipinfo.dstip', true),
          ),
        ],
      }),
      sort: {
        field: 'timestamp',
        order: 'desc',
      },
    };

    dispatch(searchActions.searchClear({ id: alertsDstipRequestId }));
    dispatch(searchActions.search(alertsRequestParams));

    return () => {
      dispatch(searchActions.cancel(alertsDstipRequestId));
    };
  }, [ip, alertsDstipRequestId, start, end]);

  useEffect(
    () => () => {
      dispatch(searchActions.searchClear({ id: alertsDstipRequestId }));
    },
    [alertsDstipRequestId],
  );

  // Blocks
  useEffect(() => {
    if (!ip) {
      return undefined;
    }

    const blocksRequestParams = {
      id: blocksSrcipRequestId,
      context: ContextTypes.blocks,
      start,
      end,
      size: 10,
      ...StatsRequest.makeSearch({
        search: [nqlLang.equal('srcip', ip)],
      }),
      sort: {
        field: 'timestamp',
        order: 'desc',
      },
    };

    dispatch(searchActions.searchClear({ id: blocksSrcipRequestId }));
    dispatch(searchActions.search(blocksRequestParams));

    return () => {
      dispatch(searchActions.cancel(blocksSrcipRequestId));
    };
  }, [ip, blocksSrcipRequestId, start, end]);

  useEffect(
    () => () => {
      dispatch(searchActions.searchClear({ id: blocksSrcipRequestId }));
    },
    [blocksSrcipRequestId],
  );

  useEffect(() => {
    if (!ip) {
      return undefined;
    }

    const blocksRequestParams = {
      id: blocksDstipRequestId,
      context: ContextTypes.blocks,
      start,
      end,
      size: 10,
      ...StatsRequest.makeSearch({
        search: [nqlLang.equal('dstip', ip)],
      }),
      sort: {
        field: 'timestamp',
        order: 'desc',
      },
    };

    dispatch(searchActions.searchClear({ id: blocksDstipRequestId }));
    dispatch(searchActions.search(blocksRequestParams));

    return () => {
      dispatch(searchActions.cancel(blocksDstipRequestId));
    };
  }, [ip, blocksDstipRequestId, start, end]);

  useEffect(
    () => () => {
      dispatch(searchActions.searchClear({ id: blocksDstipRequestId }));
    },
    [blocksDstipRequestId],
  );

  return (
    <Container>
      <GlobalFiltersSetting
        context={ContextTypes.flow}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <Menu.TriggerMenu />

      <FinalForm
        onSubmit={performSearch}
        initialValues={initialValues}
        component={FormBody}
        grabFormSubmit={grabFormSubmit}
        isFetching={isFetching}
        keepDirtyOnReinitialize
      />

      <Row>
        <Group>
          <GroupBody>
            <Card>
              <CardName>
                <Item>
                  <ItemName $width="84px" $bold>
                    Labels:
                  </ItemName>
                  <ItemBody>
                    {!!ip &&
                      !!labels?.length &&
                      UniversalCell('ipname')({
                        value: labels,
                        row: { original: { ip } },
                        options: {
                          context: filters.labelContext.ip,
                          useDataValueInPropertiesTray: true,
                        },
                      })}
                    {!!ip &&
                      !labels?.length &&
                      UniversalCell('ip')({
                        value: ip,
                        options: { showAsLabel: true },
                      })}
                    {!ip && NoDataStr}
                  </ItemBody>
                </Item>
              </CardName>
              <CardBody>
                <Item>
                  <ItemName $width="84px">First Seen:</ItemName>
                  <ItemBody>
                    {timestampFormatter(firstseen) || NoDataStr}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="84px">Last Seen:</ItemName>
                  <ItemBody>
                    {timestampFormatter(lastseen) || NoDataStr}
                  </ItemBody>
                </Item>
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group>
          <GroupBody>
            <Card>
              <CardName>Autonomous System</CardName>
              <CardBody $justifyFlexEnd>
                <Item>
                  <ItemName $width="82px">Number:</ItemName>
                  <ItemBody>{as?.number || NoDataStr}</ItemBody>
                </Item>

                <Item>
                  <ItemName $width="82px">Organization:</ItemName>
                  <ItemBody>{as?.org || NoDataStr}</ItemBody>
                </Item>
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group>
          <GroupBody>
            <Card>
              <CardName>Threat Intel</CardName>
              <CardBody>
                <Item>
                  <ItemName $width="84px">Bogon:</ItemName>
                  <ItemBody>
                    {bogon === true && (
                      <Tag color="danger" outlined={false}>
                        Yes
                      </Tag>
                    )}
                    {bogon === false && (
                      <Tag color="success" outlined={false}>
                        No
                      </Tag>
                    )}
                    {bogon === undefined && NoDataStr}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="84px">IP Reputation:</ItemName>
                  <ItemBody
                    style={{
                      whiteSpace: 'pre-line',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {iprep?.count === undefined && NoDataStr}
                    {iprep?.count === 0 && (
                      <Tag color="success" outlined={false}>
                        None
                      </Tag>
                    )}
                    {iprep?.count > 0 && (iprep?.categories || []).join('\n')}
                  </ItemBody>
                </Item>
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group>
          <GroupBody>
            <Card>
              <CardName>DNS</CardName>
              <CardBody>
                <Item>
                  <ItemName $width="50px">Reverse:</ItemName>
                  <ItemBody
                    style={{
                      whiteSpace: 'pre-line',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {rdns?.length > 0 ? rdns.join('\n') : NoDataStr}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="50px">Passive:</ItemName>
                  <ItemBody
                    style={{
                      whiteSpace: 'pre-line',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {pdns?.count > 0
                      ? (pdns.records || []).join('\n')
                      : NoDataStr}
                  </ItemBody>
                </Item>
              </CardBody>
            </Card>
          </GroupBody>
        </Group>
      </Row>

      <Row>
        <Group $width="calc(25% - 5px)">
          <GroupBody>
            <Card>
              <CardName>IP Geo</CardName>
              <CardBody>
                <Item>
                  <ItemName $width="85px">Continent:</ItemName>
                  <ItemBody>
                    {!geo?.continentcode ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.continentcode',
                          value: geo.continentcode,
                        })}
                      >
                        {geo.continentcode}
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="85px">Country:</ItemName>
                  <ItemBody>
                    {!geo?.countrycode ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.countrycode',
                          value: geo.countrycode,
                        })}
                      >
                        <Flag countryCode={geo.countrycode} />
                        <span>{geo.countrycode}</span>
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="85px">Subdivision:</ItemName>
                  <ItemBody>
                    {!geo?.subdiso ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.subdiso',
                          value: geo.subdiso,
                        })}
                      >
                        {geo.subdiso}
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="85px">Subdivision B:</ItemName>
                  <ItemBody>
                    {!geo?.subdivisionb ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.subdivisionb',
                          value: geo.subdivisionb,
                        })}
                      >
                        {geo.subdivisionb}
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="85px">City:</ItemName>
                  <ItemBody>
                    {!geo?.city ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.city',
                          value: geo.city,
                        })}
                      >
                        {geo.city}
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="85px">Postal:</ItemName>
                  <ItemBody>
                    {!geo?.postal ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.postal',
                          value: geo.postal,
                        })}
                      >
                        {geo.postal}
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>

                <Item>
                  <ItemName $width="85px">Coordinates:</ItemName>
                  <ItemBody>
                    {!(
                      geo?.location.lat != null || geo?.location.lon != null
                    ) ? (
                      NoDataStr
                    ) : (
                      <GeoContainer
                        onClick={onGeoItemClick({
                          field: 'geo.location',
                          value: geo.location,
                          title: `geo.location — ${geo.location.lat}, ${geo.location.lon}`,
                        })}
                      >
                        {geo.location.lat}, {geo.location.lon}
                      </GeoContainer>
                    )}
                  </ItemBody>
                </Item>
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group $width="calc(25% - 5px)">
          <GroupBody $height="100%">
            <Card>
              <Map geo={geo} />
            </Card>
          </GroupBody>
        </Group>

        <Group $width="50%">
          <GroupBody $height="350px">
            <Card>
              <CardName>Network Graph</CardName>
              <CardBody>
                {ip && (
                  <IpExplorer id="IpIntelligence_IpExplorer" currentIp={ip} />
                )}
              </CardBody>
            </Card>
          </GroupBody>
        </Group>
      </Row>

      <Row>
        <Group $width="40%">
          <GroupBody>
            <Group $noPadding>
              <Card>
                <CardName>Top srcports</CardName>
                <CardBody>
                  <Table
                    id={srcportsTableName}
                    columns={portColumns(
                      'src',
                      srcportsRequestFields(filters.labelContext),
                      filters.labelContext,
                    )}
                    data={srcportsData}
                    noDataText="No srcports to show"
                    pagination={false}
                    showManagerLayout={false}
                  />
                </CardBody>
              </Card>
            </Group>

            <Group $noPadding>
              <Card>
                <CardName>Top dstports</CardName>
                <CardBody>
                  <Table
                    id={dstportsTableName}
                    columns={portColumns(
                      'dst',
                      dstportsRequestFields(filters.labelContext),
                      filters.labelContext,
                    )}
                    data={dstportsData}
                    noDataText="No dstports to show"
                    pagination={false}
                    showManagerLayout={false}
                  />
                </CardBody>
              </Card>
            </Group>
          </GroupBody>
        </Group>

        <Group $width="20%">
          <GroupBody>
            <Card>
              <CardName>Top protocols</CardName>
              <CardBody>
                <Table
                  id={protocolsTableName}
                  columns={protocolColumns(protocolsRequestFields())}
                  data={protocolsData}
                  noDataText="No protocols to show"
                  pagination={false}
                  showManagerLayout={false}
                />
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group $width="40%">
          <GroupBody>
            <Group $noPadding>
              <Card>
                <CardName>Top srcips</CardName>
                <CardBody>
                  <Table
                    id={srcipsTableName}
                    columns={ipColumns(
                      'src',
                      srcipsRequestFields(filters.labelContext),
                      filters.labelContext,
                    )}
                    data={srcipsData}
                    noDataText="No srcips to show"
                    pagination={false}
                    showManagerLayout={false}
                    getHeaderGroupProps={() => ({
                      style: { justifyContent: 'flex-end' },
                    })}
                  />
                </CardBody>
              </Card>
            </Group>

            <Group $noPadding>
              <Card>
                <CardName>Top dstips</CardName>
                <CardBody>
                  <Table
                    id={dstipsTableName}
                    columns={ipColumns(
                      'dst',
                      dstipsRequestFields(filters.labelContext),
                      filters.labelContext,
                    )}
                    data={dstipsData}
                    noDataText="No dstips to show"
                    pagination={false}
                    showManagerLayout={false}
                  />
                </CardBody>
              </Card>
            </Group>
          </GroupBody>
        </Group>
      </Row>

      <Row>
        <Group>
          <GroupBody>
            <Card>
              <CardName>Latest Alerts matching srcip</CardName>
              <CardBody>
                <EventTable
                  id={alertsSrcipTableName}
                  columns={[
                    AlertTableColumns.id,
                    AlertTableColumns.severity,
                    AlertTableColumns.summary,
                    AlertTableColumns.menu,
                  ]}
                  data={alertsSrcipData}
                  pagination={false}
                  filters={null}
                />
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group>
          <GroupBody>
            <Card>
              <CardName>Latest Alerts matching dstip</CardName>
              <CardBody>
                <EventTable
                  id={alertsDstipTableName}
                  columns={[
                    AlertTableColumns.id,
                    AlertTableColumns.severity,
                    AlertTableColumns.summary,
                    AlertTableColumns.menu,
                  ]}
                  data={alertsDstipData}
                  pagination={false}
                  filters={null}
                />
              </CardBody>
            </Card>
          </GroupBody>
        </Group>
      </Row>

      <Row>
        <Group>
          <GroupBody>
            <Card>
              <CardName>Latest Blocks matching srcip</CardName>
              <CardBody>
                <BlockTable
                  id={blocksSrcipTableName}
                  columns={[
                    BlockTableColumns.expiration,
                    BlockTableColumns._srcGeo,
                    BlockTableColumns._dstOwnerAs,
                    BlockTableColumns.rules,
                    BlockTableColumns.pluginName,
                    BlockTableColumns.menu,
                  ]}
                  data={blocksSrcipData}
                  pagination={false}
                />
              </CardBody>
            </Card>
          </GroupBody>
        </Group>

        <Group>
          <GroupBody>
            <Card>
              <CardName>Latest Blocks matching dstip</CardName>
              <CardBody>
                <BlockTable
                  id={blocksDstipTableName}
                  columns={[
                    BlockTableColumns.expiration,
                    BlockTableColumns._srcGeo,
                    BlockTableColumns._dstOwnerAs,
                    BlockTableColumns.rules,
                    BlockTableColumns.pluginName,
                    BlockTableColumns.menu,
                  ]}
                  data={blocksDstipData}
                  pagination={false}
                />
              </CardBody>
            </Card>
          </GroupBody>
        </Group>
      </Row>

      <Row>
        <small>
          IP Intelligence data includes GeoLite2 data created by MaxMind,
          available from{' '}
          <a
            href="https://www.maxmind.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.maxmind.com
          </a>
        </small>
      </Row>
    </Container>
  );
};

export default withMenu(IpIntelligence);
